$(document).ready(function () {
    /*$().fancybox({
        selector : '.slick-slide:not(.slick-cloned)',
        hash     : false
    });*/

    // Init Slick

    function minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    function initSliderCount(sliderBlockName) {
        $(sliderBlockName).on("init afterChange", function (event, slick, currentSlide) {
            $(sliderBlockName).next('.slider-counts').children('.slider-counts__before').text(minTwoDigits(parseInt(slick.currentSlide + 1)));
            $(sliderBlockName).next('.slider-counts').children('.slider-counts__after').text(minTwoDigits(slick.slideCount));
        });
    }

    initSliderCount('.slider-with-pictures__wrapper');
    // initSliderCount('.product-info-grid__slider');
    initSliderCount('.education-slider__list');

    $(".education-slider__list").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        prevArrow: '<a class="education-slider__wrapper-prev" href="#"><i class="icon-spear"></i></a>',
        nextArrow: '<a class="education-slider__wrapper-next" href="#"><i class="icon-spear"></i></a>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $(".education-detail__slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
    });

    $(".slider-with-pictures__wrapper").slick({
        slidesToShow   : 1,
        slidesToScroll : 1,
        // infinite : true,
        dots     : false,
        adaptiveHeight: true,
        prevArrow: '<a class="slider-with-pictures__wrapper-prev" href="#"><i class="icon-spear"></i></a>',
        nextArrow: '<a class="slider-with-pictures__wrapper-next" href="#"><i class="icon-spear"></i></a>',
        responsive : [
            {
                breakpoint : 960,
                settings : {
                    slidesToShow   : 1,
                    slidesToScroll : 1
                }
            }
        ]
    });

    $('.main-articles__wrapper').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        prevArrow: '<a href="#" class="main-articles__prev"><i class="icon-spear"></i></a>',
        nextArrow: '<a href="#" class="main-articles__next"><i class="icon-spear"></i></a>',
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
              }
            },
          {
            breakpoint: 774,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

    $('.product-info-grid__slider')
        .on('init', function(slick) {
            $('.product-info-grid__slider').closest('.product-info-grid__wrapper').removeClass('is-hidden');
            // $('.product-info-grid__slider').fadeIn(3000);
        })
        .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        // prevArrow: '<a class="product-info-grid__wrapper-prev" href="#"><i class="icon-spear"></i></a>',
        // nextArrow: '<a class="product-info-grid__wrapper-next" href="#"><i class="icon-spear"></i></a>',
        dots: true,
        arrows : false,
        infinite: false
        // customPaging: function() {
        //     return ''
        // }
    });
    // $(".product-info-grid__slider").on("afterChange", function (){
    //     //change color here
    //     console.log('1');
    // })
    // $('.product-info-grid__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    //     console.log(nextSlide);
    // });
    // //инициализируем галерею ДО запуска слайдера
    // var gallery = $('.slider-with-pictures__wrapper-img');
    // //при клике на ссылку в слайде запускаем галерею
    // $('.slider-with-pictures__wrapper-img').on('click', function(e) {
    //     e.preventDefault();
    //     //узнаём индекс слайда без учёта клонов
    //     var totalSlides = +$(this).parents('.slider').slick("getSlick").slideCount,
    //         dataIndex = +$(this).parents('.slide').data('slick-index'),
    //         trueIndex;
    //     switch(true){
    //         case (dataIndex<0):
    //             trueIndex = totalSlides+dataIndex; break;
    //         case (dataIndex>=totalSlides):
    //             trueIndex = dataIndex%totalSlides; break;
    //         default:
    //             trueIndex = dataIndex;
    //     }
    //     //вызывается элемент галереи, соответствующий индексу слайда
    //     $.fancybox.open(gallery,{
    //         // zoom: true,
    //         // swipe: true,
    //         // touch: {
    //         //     vertical: false
    //         // }
    //     }, trueIndex);
    //     return false;
    // });
    //
    // $('.slider-with-pictures__wrapper').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     infinite: true,
    //     prevArrow: '<a class="slider-with-pictures__wrapper-prev" href="#"><i class="icon-spear"></i></a>',
    //     nextArrow: '<a class="slider-with-pictures__wrapper-next" href="#"><i class="icon-spear"></i></a>',
    //     customPaging: function() {
    //         return ''
    //     }
    // });

    // let currentSlideObj = $('.object-card-element__slider').slick('slickCurrentSlide') + 1;
    // let allSlidesObj = $(".object-card-element__slider").slick("getSlick").slideCount;
    // $(".object-card-element__slider").next('.slider-counts').children('.slider-counts__before').html('0' + currentSlideObj);
    // $(".object-card-element__slider").next('.slider-counts').children('.slider-counts__after').html('0' + allSlidesObj);


    $('.data-item__input-original').on('focus', function () {
        $(this).prev().css({'top': '15px', 'font-size': '12px'});
        $('input[type="tel"]').mask('+7(999)-999-9999');
    });
    $('.data-item__input-original').on('focusout', function () {
        if ($(this).val() == '') {
            $(this).prev().removeAttr('style');
            $('input[type="tel"]').unmask();
        }
    });



        $('.order-form-selecting__list-item').on('click', function () {
            $('selecting-item__original:checkbox').prop('checked', false);
            $(this).siblings().children('.selecting-item__original:checkbox').prop('checked', false);
        });


    $('.expand-elem_two .expand-elem__icon').addClass('expanded');
    $('.expand-elem_two .expand-elem__icon').next().animate({
        opacity: 1
    }, 1000, function () {

    });
    $('.review-section__list').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: '<a class="review-section__list-prev" href="#"><i class="icon-spear"></i></a>',
        nextArrow: '<a class="review-section__list-next" href="#"><i class="icon-spear"></i></a>',
        responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
    });

    $('.hide-show-password').click(function(){
        var type = $(this).prev().attr('type') == "text" ? "password" : 'text',
            c = $(this).html() == '<i class="icon-opened-eye"></i>' ? '<i class="icon-visibility"></i>' : '<i class="icon-opened-eye"></i>';
        $(this).html(c);
        $('#password').prop('type', type);
    });

    $('.object-includes-wrapper__slider').slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        nextArrow: '<a class="object-includes-wrapper__slider-next" href="#"><i class="icon-spear"></i></a>',
        prevArrow: '<a class="object-includes-wrapper__slider-prev" href="#"><i class="icon-spear"></i></a>'
    });
} );


function TabsToAccordeon(li, content, section) {
    if(($(li).length < 1 && $(content).length < 1 && $(section).length < 1)
        || $(section).is('.is-ready')) {
        return;
    }
    var koef = $(li).children('a').outerHeight() * $(li).length;
    var firstTab = $(li).first();
    firstTab.addClass('selected');
    firstTab.children('a').children('.icon-plus').addClass('icon-minus');

    var accordeonHeight = $(li).first().outerHeight();
    var slideHeight = $(li).first().children(content).outerHeight();
    var headHeight = $(li).first().children('a').outerHeight();

    if ($(window).width() < 768) {
        $(section).height(slideHeight + headHeight * koef);
        $(section).css('height', 'auto');
        $(section).height(accordeonHeight + koef);

    }
    else {
        setTimeout(function() {
            if ($(li).is('.product-description-tabs__item')) {
                $(section).height(slideHeight + headHeight);
            } else {
                $(section).height(slideHeight + headHeight);
            }
        }, 500);
    }

    $(li).animate({}, 700, function () {
        const $context = $(this);
        $context.on('click', `a`, function (e) {
            if ($(window).width() < 768) {
                $context.toggleClass('selected');

            } else {
                $context.addClass('selected');

            }

            $context.siblings().removeClass('selected');
            $(li).each(function () {
                if ($(this).hasClass('selected')) {
                    $(this).children('a').children('.icon-plus').addClass('icon-minus');
                    $(this).siblings().children('a').children('.icon-plus').removeClass('icon-minus');
                } else {
                    $(this).children('a').children('.icon-plus').removeClass('icon-minus');
                }
            });

            var accordeonHeight = $context.outerHeight();
            slideHeight = $context.children(content).outerHeight();
            headHeight = $context.children('a').outerHeight();
            if ($(window).width() < 768) {
                $(section).css('height', 'auto');
                $(section).height(accordeonHeight + koef);
            } else {
                $(section).height(slideHeight + headHeight);

            }
        });
    });

    $(section).addClass('is-ready');

}

$(document).ready(function () {
    //TabsToAccordeon('.tabs-grid__point', '.info-banner__tabs-content', '#main_tabs');
    TabsToAccordeon('.product-description-tabs__item', '.product-description-tabs__content', '.product-more-description');
    //TabsToAccordeon('#steps_of_works .work-steps-column__item', '.work-steps-content', '.steps-of-works');
    TabsToAccordeon('#solution_docs li', '.solution-docs__content', '.docs');
    TabsToAccordeon('.object-docs-tabs__item', '.object-docs-tabs__content', '.object-docs');

    $('.text-for-search__card-close').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.text-for-search__card').find('input').val('');
    });

    $(document).on('click', '.work-steps-content__info img', function() {
       const $img = $(this);
       if ($img.is('[data-fancybox]')) return;

       $.fancybox.open({
           src: $img.attr('src'),
           type: 'image'
       });
    });
});

function adaptiveHeight(li, content, section) {
    var slideHeight = $(li).first().children(content).outerHeight();
    var headHeight = $(li).first().children('a').outerHeight();
    if ($(window).width() < 768) {
        $(section).css('height', 'auto');
    } else {
        let currentHeight = $(section).find('li.selected ' + content).outerHeight();
        $(section).height(currentHeight);
    }
}

$(window).on("resize", function () {
    adaptiveHeight('.tabs-grid__point', '.info-banner__tabs-content', '#main_tabs');
    adaptiveHeight('.product-more-description li', '.product-description-tabs__content', '.product-more-description');
    //adaptiveHeight('#steps_of_works .work-steps-column__item','.work-steps-content', '.steps-of-works');
    adaptiveHeight('#solution_docs li', '.solution-docs__content', '.docs');
});


$(document).on('click', '.expand-elem__icon',  function (e) {
    e.preventDefault();
    $(this).animate({}, 700, function () {
        $(this).toggleClass('expanded');
    });
    if ($(this).hasClass('expanded')) {
        $(this).next().animate({
            opacity: 1
        }, 700, function () {

        });
    } else {
        $(this).next().animate({
            opacity: 0
        }, 700, function () {

        });
    }
});

$(document).ready(function () {
    function scrollToTop(current_link) {
        $(current_link).on("click", function (event) {
            //отменяем стандартную обработку нажатия по ссылке
            event.preventDefault();
            //забираем идентификатор бока с атрибута href
            var id  = $(this).attr('href'),
                //узнаем высоту от начала страницы до блока на который ссылается якорь
                top = $(id).offset().top - 103;
            //анимируем переход на расстояние - top за 1500 мс
            $('body,html').animate({scrollTop: top}, 300);

            // if(current_link == '#steps_of_works .work-steps-column__item > a') {
            //     $('.work-steps-column').stop().animate({marginTop: 0});
            // }
        });
    }

    scrollToTop("#animate_scroll_to_professional");
    scrollToTop('.main-jumbotron__scroll');
    // scrollToTop('.work-steps-column__item > a');

    // if ($(window).width() < 992) {
    //     scrollToTop('.product-description-tabs__item > a');
    // }
    if ($(window).width() < 768) {
        scrollToTop('.tabs-grid__point > a');
    }
    //scrollToTop('#steps_of_works .work-steps-column__item > a');
});
(function($){
    $(window).on("load resize",function(){
        $(".table-imitation").mCustomScrollbar({
            theme:"dark",
            axis:"x"
        });
        $('.content-info-block__table').mCustomScrollbar({
            theme:"dark",
            axis:"x"
        });
    });
})(jQuery);

$(document).ready(function () {
    $('.steps-of-works').on('click', '.steps-of-works__menu-link', function (e) {
        const $contextLink = $(this);
        const $contextItem = $contextLink.closest('.js-item-parent');
        const $targetItem = $($contextLink.attr('href')).closest('.js-item-parent');
        const $parentBlock = $contextLink.closest('#steps_of_works');
        const resolutionTopOffset = $(window).width() < 1331 ? 70 : 110;
        e.preventDefault();
        $targetItem.add($contextItem).addClass('selected').siblings().removeClass('selected');
        $('html, body').scrollTop($parentBlock.offset().top - resolutionTopOffset);
    });
});

$(document).ready(function () {
    $('.data-item__upload-original').on('change', function() {
        var $field = $(this),
            $parent = $(this).closest('.data-item__upload'),
            splittedFakePath = this.value.split('\\');
        $('.upload-fake__text').text('Прикрепить файл');
        $('.data-item__upload').after('<div class="added-file"><div class="added-file__name">' + splittedFakePath[splittedFakePath.length - 1] + '</div><div class="added-file__remove"><i class="icon-close"></i></div></div>');
        $(document).on('click', '.added-file__remove', function(){
            $field.val('');
            $(this).closest('.added-file').remove();
        });
    });

    $('#navigation_map').tabs();

    $('.placemark_layout_container').on('click', function (e) {
        e.preventDefault();
       $(this).closest('#map_obj').next('objects-map-card').show();
    });
    $(document).on('click', '.objects-map-card__exemplar-close', function (e) {
        e.preventDefault();
        $(this).closest('.objects-map-card').hide();
        $('.placemark_layout_container').removeClass('active');
    });




    $('.object-card-element__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a href="#" class="object-card-element__slider-prev"><i class="icon-spear"></i></a>',
        nextArrow: '<a href="#" class="object-card-element__slider-next"><i class="icon-spear"></i></a>',
        infinite: false
    });

    $('.object-card-element').each(function (index, element) {
        let currentSlideObj = $(this).find('.object-card-element__slider').slick('slickCurrentSlide') + 1;
        let allSlidesObj = $(this).find(".object-card-element__slider").slick("getSlick").slideCount;
        if (currentSlideObj / 10 < 1) {
            currentSlideObj = '0' + currentSlideObj;
        }
        if (allSlidesObj / 10 < 1) {
            allSlidesObj = '0' + allSlidesObj;
        }
        $(this).find(".object-card-element__slider").next('.slider-counts').children('.slider-counts__before').html(currentSlideObj);
        $(this).find(".object-card-element__slider").next('.slider-counts').children('.slider-counts__after').html(allSlidesObj);
    });
    $('.object-includes-wrapper').each(function (index, element) {
        let currentSlideObj = $(this).find('.object-includes-wrapper__slider').slick('slickCurrentSlide') + 1;
        let allSlidesObj = $(this).find(".object-includes-wrapper__slider").slick("getSlick").slideCount;
        if (currentSlideObj / 10 < 1) {
            currentSlideObj = '0' + currentSlideObj;
        }
        if (allSlidesObj / 10 < 1) {
            allSlidesObj = '0' + allSlidesObj;
        }
        $(this).find(".object-includes-wrapper__slider").next('.slider-counts').children('.slider-counts__before').html(currentSlideObj);
        $(this).find(".object-includes-wrapper__slider").next('.slider-counts').children('.slider-counts__after').html(allSlidesObj);
    });
    $('input[type="tel"]').inputmask();
});

$(document).on('click', '.object-card-element__slider .slick-arrow', function () {
    let currentSlideObj = $(this).closest('.object-card-element__slider').slick('slickCurrentSlide') + 1;
    let allSlidesObj = $(this).closest(".object-card-element__slider").slick("getSlick").slideCount;
    if (currentSlideObj / 10 < 1) {
        currentSlideObj = '0' + currentSlideObj;
    }
    if (allSlidesObj / 10 < 1) {
        allSlidesObj = '0' + allSlidesObj;
    }
    $(this).closest(".object-card-element__slider").next('.slider-counts').children('.slider-counts__before').html(currentSlideObj);
    $(this).closest(".object-card-element__slider").next('.slider-counts').children('.slider-counts__after').html(allSlidesObj);
});
$(document).on('click', '.object-includes-wrapper__slider .slick-arrow', function () {
    let currentSlideObj = $(this).closest('.object-includes-wrapper__slider').slick('slickCurrentSlide') + 1;
    let allSlidesObj = $(this).closest(".object-includes-wrapper__slider").slick("getSlick").slideCount;
    if (currentSlideObj / 10 < 1) {
        currentSlideObj = '0' + currentSlideObj;
    }
    if (allSlidesObj / 10 < 1) {
        allSlidesObj = '0' + allSlidesObj;
    }
    $(this).closest(".object-includes-wrapper__slider").next('.slider-counts').children('.slider-counts__before').html(currentSlideObj);
    $(this).closest(".object-includes-wrapper__slider").next('.slider-counts').children('.slider-counts__after').html(allSlidesObj);
});
$(document).ready(function () {
    $('.favorites-area').on('click', function () {
        $(this).toggleClass('added');
        if ($(this).hasClass('added')) {
            $(this).children('.favorites-area__add').text('В избранном');
        } else {
            $(this).children('.favorites-area__add').text('Добавить в избранное');
        }
    });
});

$(function(){
    $('.js-under-cut').each(function(){
        var $context = $(this),
            $btn = $('.js-uncut', $context),
            btnInitialText = $btn.text(),
            btnOpenedText = $btn.attr('data-text-opened');

        if ($context[0].scrollHeight < 700) {
            $context.removeClass('js-under-cut');
            $btn.hide();
        }

        $btn.on('click', function(e){
            e.preventDefault();
            if($context.is('.is-open')) {
                $context.removeClass('is-open')
                $btn.text(btnInitialText);
                $("html, body").animate({
                    scrollTop: $context.offset().top - 160
                }, 1e3)
            } else {
                $context.addClass('is-open');
                $btn.text(btnOpenedText);
            }
        })
    })

});
$(document).ready(function () {

    var btn = $('#button');
    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });

    btn.on('click', function() {
        // e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });

    // var module = $(".card-substrate__wrapper-text");
    // module.each(function( index ) {
    //     $clamp(module[index], {clamp: 3})
    // });
    // $clamp(module, {clamp: 3});
});
$(function () {
    $('.info-banner__tabs').each(function(){
        var $context = $(this),
            $tabs = $('.tabs-grid__point', $context),
            maxHeight = 0;

        $tabs.eq(0).addClass('selected');

        $tabs.each(function(){
            var $tab = $(this),
                $link = $tab.first().children('a'),
                $content = $('.info-banner__tabs-content', $tab);

            if($content.height() > maxHeight) {
                maxHeight = $content.height();
            }
            // $tab.on('click', function () {
            //     if($(window).width() < 767) {
            //         $tab.toggleClass('selected');
            //     }
            // });
            $link.on('click', function(){
                if($(window).width() < 767) {
                    let openTabs = $tab.hasClass('selected');
                    $tabs.removeClass('selected');
                    $tab.addClass('selected');
                    if(openTabs){
                        $tab.removeClass('selected');
                    }
                    else{
                        $tab.addClass('selected');
                    }
                }else{
                    $tabs.removeClass('selected');
                    $tab.addClass('selected');
                }
            });
//             $tab.on('click', function(){
//                 $tab.toggleClass('selected');
//             });
        });
        if($(window).width() > 767) {
            $context.height(maxHeight + 55);
        }

    })
});
$( function() {
    function SelectMenu(selectId, onchange) {
        $(selectId).each(function(){
            var $this=$(this);
            $this.selectmenu({
                change: onchange
            });
            var menu=$this.selectmenu("menuWidget");
            var button=$this.selectmenu("widget");
            button.click();
            var longest=null;
            menu.children("li").each(function(){
                var $this=$(this);
                if(longest==null || longest.text().length < $this.text().length){
                    longest=$this;
                }
            });
            menu.css("width","auto");
            var width=longest.mouseover().width();
            button.click();
            var paddingRight=parseFloat(button.children().last().css("padding-right"));
            var paddingLeft=parseFloat(button.children().last().css("padding-left"));
            var total=width+paddingLeft+paddingRight + 2;
            // $this.selectmenu("option","width", total);
        });
    }
    SelectMenu('#type');
    SelectMenu('#activity');
    SelectMenu('#city');
    SelectMenu('#direction');
    // SelectMenu('#project_variant', function( event, ui ) {
    //     const $customActivityField = $('.js-custom-activity-field');
    //     if (ui.item.value === "12") {
    //         $customActivityField.show();
    //     } else {
    //         $customActivityField.hide();
    //     }
    // });
    $("#project_variant").select2({
        width: '100%'
    });
    $("#project_variant").on('change', function (e) {
        const $selectedOptions = $(':selected', $(this));
        const optionsValues = [];
        const $customActivityField = $('.js-custom-activity-field');
        $selectedOptions.each(function(){
            optionsValues.push($(this).val());
        });

        if (optionsValues.includes("12")) {
            $customActivityField.show();
        } else {
            $customActivityField.hide();
        }
    });
    SelectMenu('#material_1');
    SelectMenu('#TYPE_MATERIAL');
    SelectMenu('#material_2');
    SelectMenu('#status');
    SelectMenu('#date');
    SelectMenu('#obj_area');
    SelectMenu('#select_city');
    SelectMenu('#select_activity');
    if($('.date-input').length)
    {
        $('.date-input').datetimepicker({
            timepicker:false,
            format:'d/m/Y',
            onChangeDateTime:function($input){

            }
        });
    }
} );

$(document).ready(function () {
    fakeSelect();

    $('.object-stages__list').slick({
        infinite: false,
        speed: 300,
        infinite: true,
        prevArrow: $('.object-stages .object-stages__prev'),
        nextArrow: $('.object-stages .object-stages__next'),
    }).on('afterChange', function (event, slick, currentSlide) {
        $('.object-stages__counter span').text((currentSlide + 1) < 10 ? '0'+(currentSlide+1) : currentSlide+1);
    });
})
function fakeSelect() {
    $(document).on('click', '.fake-select .fake-select__field', function() {
        $('.fake-select').not($(this).parent()).removeClass('is-open');
        $(this).parent().toggleClass('is-open');
    });

    $(document).on('change', '.fake-select input', function() {
        const parentSelectField = $(this).closest('.fake-select').find('.fake-select__field');
        const list = $(this).closest('.fake-select__list');
        const selectedInputs = list.find('input:checked');
        let names = [];

        selectedInputs.each(function (i) {
            const name = $(selectedInputs[i]).siblings('.selecting-item__name').text().trim();
            names.push(name);
        })

        if (names.length > 0) {
            parentSelectField.text(names.join(', '));
            parentSelectField.attr('title', names.join(', '));
        } else {
            parentSelectField.text('');
            parentSelectField.removeAttr('title');
        }
    })

    $(document).on('click', function (e) {
        if (!$(e.target).closest('.fake-select').length) {
            $('.fake-select').removeClass('is-open');
        }
    })
}


// $(document).ready(function () {
//     var header = document.getElementsByTagName('body')[0].querySelectorAll('.card-substrate__wrapper-text'),
//
//         $clamp(header, {clamp: 1, useNativeClamp: false});
// });


